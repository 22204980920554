<div class="h-14 flex gap-2 justify-between items-center px-6 bg-blue-600">
  <div class="flex items-center gap-3">
    <i
      *ngIf="isSmallScreen"
      class="pi pi-bars p-1 cursor-pointer hover:text-blue-500 text-white"
      (click)="openSidebar.emit(true)"
    ></i>
    <a [href]="adminHubUrl" class="flex items-center gap-2 cursor-pointer">
      <img
        class="inline-block w-auto h-[1.875rem] object-contain object-center"
        src="../assets/imgs/logo/logo-text-white.svg"
        alt="Soctrip Logo"
      />
    </a>
    <div class="text-white text-lg">{{ headerEnum.TRAVEL_TOUR_ADMIN }}</div>
  </div>

  <div class="flex items-center gap-4 lg:gap-6">
    <app-flags-dropdown
      (languageChange)="changeLanguage($event)"
    ></app-flags-dropdown>
    <!-- <a
      *ngIf="env.SOCCHAT_VISIBLE"
      aria-label="admin-support"
      [routerLink]="['/', adminSupportUrl]"
      class="font-medium text-white rounded-md text-md flex "
      routerLinkActive="text-primary-600 font-semibold"
      [pTooltip]="'admin-support.title' | translate"
      tooltipPosition="bottom"
      tooltipStyleClass="t-tooltip-light"
    >
      <i
        pBadge
        [value]="chatBadgeCount"
        severity="danger"
        class="t-bell-noti-header sctr-icon-message-chat-square cursor-pointer text-2xl/none text-white"
      ></i>
    </a> -->
    <div class="relative cursor-pointer hidden">
      <i class="pi pi-bell !text-xl text-white"></i>
      <span
        class="absolute w-2 h-2 bg-orange-dark-500 rounded-full left-3 top-[0.125rem]"
      ></span>
    </div>

    <div (click)="toggle()" class="flex items-center gap-3 cursor-pointer">
      <img
        class="inline-block w-[1.875rem] h-[1.875rem] object-contain object-center rounded-full"
        [src]="user?.avatarURL"
        onerror="this.src='assets/imgs/default/avatar.webp'"
      />
      <div
        *ngIf="user?.full_name"
        class="grid text-sm font-semibold text-white"
      >
        <span>{{ user?.full_name }}</span>
      </div>
    </div>
    <p-overlay
      class="absolute left-[-20px] top-4"
      [(visible)]="overlayVisible"
      [styleClass]="'z-10 min-w-0 top-0 left-0'"
    >
      <div
        class="w-[260px] px-[6px] py-1 bg-white p-0 border border-gray-200 rounded-lg"
      >
        <div
          class="flex items-center gap-2 text-orange-dark-600 min-w-[200px] px-4 py-2 cursor-pointer hover:bg-gray-100"
          (click)="handleSignOut()"
        >
          <i class="sctr-icon-log-out-01"></i>
          <div class="text-sm">Sign out</div>
        </div>
      </div>
    </p-overlay>
  </div>
</div>
