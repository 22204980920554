import { IEnvironment } from './interface/IEnvironment.interface';
import { PREFIX } from './path-consts/prefix.env';

export const environment: IEnvironment = {
  state: 'DEVELOPMENT',
  baseURL: 'https://api-dev-tmtrav.tma-swerp.com',

  FE_URL: `https://tmtrav-travel-admin-${PREFIX.DEV}.tma-swerp.com/`,
  // MARKETING_HUB_DOMAIN_URL: `https://tmtrav-marketing-hub-${PREFIX.DEV}.tma-swerp.com`,
  MARKETING_HUB_DOMAIN_URL: `https://tmtrav-shop-admin-${PREFIX.DEV}.tma-swerp.com`,
  ADMIN_HUB_URL: 'https://admin-dev.tma-swerp.com',
  REFUND_ADMIN_HUB_URL: 'https://tmtrav-shop-admin-dev.tma-swerp.com',
  VOUCHER_ADMIN_HUB_URL: 'https://tmtrav-voucher-hub-dev.tma-swerp.com',
  BANNER_HUB_URL: 'https://tmtrav-banner-dev.tma-swerp.com',

  BE_URL: `https://api-${PREFIX.DEV}-tmtrav.tma-swerp.com/`,
  IMAGE_URL: `https://api-${PREFIX.DEV}.tma-swerp.com/ecommerce-management`,
  IFRAME_CONNECT_URL: `https://admin-dev.tma-swerp.com/app-connect`,
  LOGIN_URL: `https://tmtrav-${PREFIX.DEV}.tma-swerp.com`,
  SERVICES: {
    STOCK: 'stock',
    CATALOG: 'catalog',
    SHOP: 'shop',
    STORAGE: 'storage',
    STORAGE_STREAMING: 'storage-streaming',
    ORDER: 'order',
    PROMOTION: 'promotion',
    PAYMENT: 'payment',
    SHIPMENT: 'shipment',
    ADVERTISING: 'advertising',
    TRAVEL_ADMIN: 'travel-admin',
    BILLING: 'billing',
    REGIONAL: 'regional',
    MAP_LOCATION: 'map-location',
  },
  PAGE_TITLE: 'Soctrip',
  APP_NAME: 'TM Travel Content Approval',
  COMPANY_NAME: 'TMA Technology Group',
  TOKEN: 'token_admin',
  AUTH_STATUS: 'auth_status_admin',
  USER_PROFILE: 'auth',
  SOCIAL_APP_URL: 'https://tmtrav-dev.tma-swerp.com',
  TOKEN_KEY: 'accessToken',
  REFRESH_TOKEN_KEY: 'refresh_token',
  USER_ROLE_KEY: 'user_role',
  REFRESH_TOKEN_EXPIRY_KEY: 'refresh_token_expiry_date',
  ACCESS_TOKEN_EXPIRY_KEY: 'access_token_expiry_date',
  BASE_API_URL: 'https://api-dev-tmtrav.tma-swerp.com',
  TRAVEL_ASSIST_URL: 'https://tmtrav-travel-dev.tma-swerp.com',
  BOOKING_URL: 'https://tmtrav-booking-dev.tma-swerp.com',
  ECOMMERCE_FOOD_URL: 'https://tmtrav-food-dev.tma-swerp.com',
  ECOMMERCE_URL: 'https://tmtrav-ecommerce-dev.tma-swerp.com',
  API_TIMEOUT: 10000,
  CHAT_MODULE: 'TRAVEL',
  SOCCHAT_VISIBLE: true,
  PROMOTION_MANAGEMENT_VISIBLE: true,
  TOUR_MANAGEMENT_VISIBLE: true,
  TOUR_LABEL_VISIBLE: true,
  ADVENTURE_STYLE_VISIBLE: true,
  LANDING_PAGE_VISIBLE: true,
  RSS_FEED: true,
  CHAT_FAQ_PORTAL_URL: 'https://chat-faq-admin.tma-swerp.com',
  FLIGHT_ADMIN_URL: 'https://flight-admin-tmtrav.tma-swerp.com',
  CHAT_DOMAIN: 'tmtrav',
};
